<template>
  <div>
    <confirmation ref="Confirmation"></confirmation>
    <datatable-heading
      :title="$t('menu.affiliates')"
      :changePageSize="changePageSize"
      :searchChange="_.debounce(searchChange, 500)"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage"
      :tableFields="fields"
      @filterChange="filterChange"
    >
      <template v-slot:button>
        <download-excel :data="json_data" type="csv">
          <b-button v-b-modal.modalright variant="primary" size="lg">
            Export
          </b-button>
        </download-excel>
        <b-button
          class="ml-2"
          v-b-modal.modalright
          variant="primary"
          size="lg"
          :to="{
            name: 'add-affiliate'
          }"
          >Add New</b-button
        >
        <!-- params: { roundRobinTypeID: roundRobinTypeID } -->
      </template>
    </datatable-heading>
    <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
      <b-row>
        <b-colxx xxs="12">
          <b-card>
            <vuetable
              ref="vuetable"
              track-by="affiliateID"
              :api-url="apiBase"
              :query-params="makeQueryParams"
              :per-page="perPage"
              :reactive-api-url="false"
              :fields="fields"
              :css="css.table"
              pagination-path
              :row-class="onRowClass"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:row-clicked="rowClicked"
              @vuetable:cell-rightclicked="rightClicked"
              @vuetable:load-error="handleLoadError"
              @vuetable:cell-clicked="cellclicked"
              @vuetable:loading="onVuetableLoading"
              @vuetable:loaded="onVuetableLoaded"
            >
              <template slot="stateCode" slot-scope="props">
                <read-more-list :linesToShow="2" separator="," :itemsInLine="5" :listText="props.rowData.stateCode" />
              </template>
              <template slot="active" slot-scope="props">
                <div
                  style="display:flex;justify-content:center;align-items;center;"
                >
                  <toggle-switch
                    v-on:input="flipActive(props.rowData)"
                    v-model="props.rowData.active"
                    :emitOnMount="false"
                    theme="custom"
                    class="vue-switcher-small"
                    color="primary"
                    :id="props.rowData.affiliateTypeName"
                    v-b-tooltip.hover.left
                    :title="
                      `Activate/Deactivate ${props.rowData.companyName}`
                    "
                  />
                </div>
              </template>
            </vuetable>
          </b-card>
          <vuetable-pagination-bootstrap
            class="mt-4"
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />
        </b-colxx>
      </b-row>
    </b-overlay>
    <v-contextmenu ref="contextmenu">
      <v-contextmenu-item @click="onContextMenuAction('edit')">
        <i class="simple-icon-docs" />
        <span>Edit</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('activate')">
        <i class="simple-icon-trash" />
        <span>Activate</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('deactivate')">
        <i class="simple-icon-trash" />
        <span>Deactivate</span>
      </v-contextmenu-item>
    </v-contextmenu>
  </div>
</template>
<script>
import Confirmation from '../../../components/Common/Confirmation.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../components/Common/VuetablePaginationBootstrap'
import { BeaconapiUrl, AffiliateContext } from '../../../constants/config'
import DatatableHeading from '../../../containers/datatable/DatatableHeading'
import CssTableConfig from '../../../constants/VuetableBootstrapconfig'
import AffiliateMixin from '../../../mixins/AffiliateMixin.vue'
import { mapMutations } from 'vuex'
import ReadMoreList from '../../../components/Listing/ReadMoreList.vue'
import switches from 'vue-switches'
import bus from '../../../main'
import JsonExcel from 'vue-json-excel'

export default {
  props: {
    affiliateID: {
      type: Number,
      default: 1
    }
  },
  components: {
    Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Confirmation,
    'read-more-list': ReadMoreList,
    'toggle-switch': switches,
    downloadExcel: JsonExcel
  },
  mixins: [AffiliateMixin],
  data () {
    var _idfield = 'affiliateID'
    return {
      isLoad: false,
      apiBase: `${BeaconapiUrl}/api/affiliate/table`,
      sort: '',
      page: 1,
      perPage: 50,
      search: '',
      filters: [],
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      fields: [
        {
          name: '__checkbox',
          title: '',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right selected',
          width: '5%'
        },
        {
          name: _idfield,
          sortField: _idfield,
          title: 'Affiliate ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '9%',
          filterType: 'number'
        },
        {
          name: 'companyName',
          sortField: 'companyName',
          title: 'Affiliate Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%',
          filterType: 'text'
        },
        {
          name: 'phoneNumber',
          sortField: 'phoneNumber',
          title: 'Phone Number',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: 'affiliateTypeName',
          sortField: 'affiliateTypeName',
          title: 'Affiliate Type Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '8%',
          filterType: 'text'
        },
        {
          name: '__slot:stateCode',
          sortField: 'stateCode',
          title: 'State Code',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'text'
        },
        {
          name: '__slot:active',
          sortField: 'active',
          title: 'Active',
          titleClass: 'center aligned text-center',
          dataClass: 'center aligned text-center',
          width: '10%',
          filterType: 'active'
        }
      ],
      json_data: []
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage'
    ]),
    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
      this.json_data = paginationData.data
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    filterChange (filters) {
      this.filters = filters
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected'
      }
      return outputclass
    },
    cellclicked (dataitem, field, event) {},
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]
      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    rowClicked (dataItem, event) {
      const itemId = dataItem[this.$data.idfield]

      if (this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = this.$refs.vuetable.selectedTo.filter(
          x => x !== itemId
        )
        localStorage.setItem(AffiliateContext, null)
      } else {
        this.$refs.vuetable.selectedTo.pop()
        this.$refs.vuetable.selectedTo.push(itemId)
        localStorage.setItem(AffiliateContext, itemId)
        bus.$emit('buildaffiliatecontext')
        bus.$emit('openaffiliatesubmenu')
      }

      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    onContextMenuAction (action) {
      var matchedItems = this.$refs.vuetable.tableData.filter(item =>
        this.selectedItems.includes(item.affiliateID)
      )
      var affiliateNames = matchedItems
        .map(affiliate => affiliate.companyName)
        .join(', ')
      switch (action) {
        case 'edit': {
          if (this.selectedItems.length > 1) {
            this.$refs.Confirmation.show({
              title: 'Error',
              message: 'You may only edit one item at a time',
              okButton: 'Cancel',
              exitFunction: e => {
                if (['headerclose', 'esc', 'backdrop'].includes(e.trigger)) {
                  this.refreshVueTable()
                }
              }
            })
          } else {
            this.$router.push({
              name: 'edit-affiliate',
              params: { affiliateID: this.selectedItems[0] }
            })
          }
          break
        }
        case 'activate': {
          this.$refs.Confirmation.show({
            title: 'Activate Affiliates',
            message: `Are you sure you want to activate the following affiliates: ${affiliateNames}?`,
            okButton: 'Activate'
          }).then(result => {
            if (result) {
              this.setInfoMessage('')
              this.activateItems(true, matchedItems)
            }
          })
          break
        }
        case 'deactivate': {
          this.$refs.Confirmation.show({
            title: 'Deactivate Affiliates',
            message: `Are you sure you want to deactivate the following affiliates: ${affiliateNames}?`,
            okButton: 'Deactivate'
          }).then(result => {
            if (result) {
              this.setInfoMessage('')
              this.activateItems(false, matchedItems)
            }
          })
          break
        }
        default:
          break
      }
    },
    flipActive (affiliate) {
      this.$refs.Confirmation.show({
        title: affiliate.active ? 'Activate Affiliate' : 'Deactivate Affiliate',
        message:
          'Are you sure you want to ' +
          (affiliate.active ? 'activate' : 'deactivate') +
          ` ${affiliate.companyName}?`,
        okButton: 'Yes',
        exitFunction: e => {
          if (['headerclose', 'esc', 'backdrop'].includes(e.trigger)) {
            this.refreshVueTable()
          }
        }
      }).then(async result => {
        if (result) {
          const affiliateObj = [
            {
              ID: affiliate.affiliateID,
              active: affiliate.active
            }
          ]
          this.setInfoMessage('')
          await this.activateAffiliate(affiliateObj).then(response => {
            this.refreshVueTable()
          })
        } else {
          this.refreshVueTable()
        }
      })
    },
    async activateItems (activateBool, matchedItems) {
      var activateAffiliatesList = matchedItems.map(affiliate => ({
        ID: affiliate.affiliateID,
        active: activateBool
      }))
      await this.activateAffiliate(activateAffiliatesList).then(response => {
        this.refreshVueTable()
      })
    }
  }
}
</script>
